.blog-card {
  position: relative;
  display: inline-flex;
  justify-content: space-between;
  height: 500px;
  overflow: hidden;
  border-radius: 10px;
  box-shadow: 3px 3px 20px rgba(0, 0, 0, 0.5);
}

.gradient-overlay {
  background-image: -webkit-linear-gradient(rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.6) 21%);
  background-image: -moz-linear-gradient(rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.6) 21%);
  background-image: -o-linear-gradient(rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.6) 21%);
  background-image: linear-gradient(rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.6) 21%);
  position: absolute;
  width:100%;
  height: 30%;
  top: 70%;
}

.color-overlay {
  background: rgba(84, 104, 110, 0.4);
  transition: background 0.3s cubic-bezier(0.33, 0.66, 0.66, 1);
  position: absolute;
  width:100%;
  height: 100%;
}

.blog-card:hover .color-overlay {
  background: rgba(84, 104, 110, 0.8);
}

.card-content {
  display: flex;
  flex-grow: 1;
  z-index: 1;
  flex-direction: column;
  justify-content: space-between;
}

.title-content h3 {
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 2px;
  color: #9CC9E3;
  font-family: 'Roboto', sans-serif;
  text-align: center;
}

.title-content hr {
  width: 50px;
  height: 3px;
  margin: 20px auto;
  border: 0;
  background: #D0BB57;
}

.introduction {
  color: #DCE3E7;
  font-family: 'Droid Serif', serif;
  font-size: 13px;
  font-style: italic;
  line-height: 18px;
  text-align: center;
}

.card-info {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  margin: 0 auto;
  padding: 10px 50px;
  color: #DCE3E7;
  font-family: 'Droid Serif', serif;
  line-height: 24px;
  z-index: 2;
  opacity: 0;
  transition: bottom 0.3s, opacity 0.3s cubic-bezier(0.33, 0.66, 0.66, 1);
}

.blog-card:hover .card-info {
  opacity: 1;
}

.card-button {
  background: transparent;
  outline: none !important;
  line-height: 42px;
  padding: 0;
  border: solid 2px;
  text-align: center;
  margin-top: 150px;
}

.card-button:hover {
  color: #c4ac6f;
}


.utility-list {
  list-style-type: none;
  margin: 0 0 30px 20px;
  padding: 0;
  width: 100%;
}
.utility-list li {
  margin: 0 15px 0 0;
  padding: 0 0 0 22px;
  display: inline-block;
  color: #DCE3E7;
  font-family: 'Roboto', sans-serif;
}
.utility-list li.time {
  background: url('./assets/time.svg') no-repeat;
}
.utility-list li.date {
  background: url('./assets/calendar.svg') no-repeat;
}