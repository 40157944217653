.project-container {
  width: 95%;
  position: relative;
  max-width: 800px;
  margin: auto;
  background: #fff;
  box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2);
  padding: 25px;
  border-radius: 25px;
  height: 400px;
  transition: all .3s;
}
@media screen and (max-width: 992px) {
  .project-container {
    max-width: 680px;
    height: 400px;
  }
}
@media screen and (max-width: 768px) {
  .project-container {
    min-height: 500px;
    height: auto;
    margin: 180px auto;
  }
}
@media screen and (max-height: 500px) and (min-width: 992px) {
  .project-container {
    height: 350px;
  }
}

.swiper-slide {
  padding-left: 90px;
}


.swiper-slide-active .project__img img {
  opacity: 1;
  transition-delay: .3s;
}
.swiper-slide-active .project__content > * {
  opacity: 1;
  transform: none;
}
.swiper-slide-active .project__content > *:nth-child(1) {
  transition-delay: 0.3s;
}
.swiper-slide-active .project__content > *:nth-child(2) {
  transition-delay: 0.4s;
}
.swiper-slide-active .project__content > *:nth-child(3) {
  transition-delay: 0.5s;
}

.project__img {
  width: 300px;
  flex-shrink: 0;
  height: 300px;
  box-shadow: 4px 13px 30px 1px #323c41;
  border-radius: 20px;
  transform: translateX(-80px);
}
.project__img:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 20px;
  opacity: 0.8;
}
.project__img img {
  width: 100%;
  height: 100%;
  object-fit: fill;
  display: block;
  opacity: 0;
  border-radius: 20px;
  transition: all .3s;
}
@media screen and (max-width: 768px) {
  .project__img {
    transform: translateY(-50%);
    width: 90%;
  }
}
@media screen and (max-width: 576px) {
  .project__img {
    width: 95%;
  }
}
@media screen and (max-height: 500px) and (min-width: 992px) {
  .project__img {
    height: 270px;
  }
}
.project__content {
  padding-right: 25px;
  margin-top: -250px;
  margin-left: 300px;
}
@media screen and (max-width: 768px) {
  .project__content {
    margin-top: -80px;
    text-align: center;
    padding: 0 30px;
    margin-left: 0;
  }
}
@media screen and (max-width: 576px) {
  .project__content {
    padding: 0;
    margin-left: 0;
  }
}
.project__content > * {
  opacity: 0;
  transform: translateY(25px);
  transition: all .4s;
}
.project__code {
  color: #7b7992;
  margin-bottom: 15px;
  display: block;
  font-weight: 500;
}
.project__title {
  font-size: 24px;
  font-weight: 700;
  color: #0d0925;
  margin-bottom: 20px;
}
.project__text {
  color: #4e4a67;
  margin-bottom: 30px;
  line-height: 1.5em;
}
.project__button {
  display: inline-flex;
  background: #c4ac6f;
  padding: 15px 35px;
  border-radius: 50px;
  color: #fff;
  text-decoration: none;
  font-weight: 500;
  justify-content: center;
  text-align: center;
  letter-spacing: 1px;
}
@media screen and (max-width: 576px) {
  .project__button {
    width: 100%;
  }
}
.project .swiper-container-horizontal > .swiper-pagination-bullets, .project .swiper-pagination-custom, .project .swiper-pagination-fraction {
  bottom: 10px;
  left: 0;
  width: 100%;
}
.swiper-pagination {
  position: absolute;
  z-index: 21;
  right: 20px;
  width: 11px !important;
  text-align: center;
  left: auto !important;
  top: 50%;
  bottom: auto !important;
  transform: translateY(-50%);
}
@media screen and (max-width: 768px) {
  .swiper-pagination {
    transform: translateX(-50%);
    left: 50% !important;
    top: 205px;
    width: 100% !important;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.swiper-pagination.swiper-pagination-bullets .swiper-pagination-bullet {
  margin: 8px 0;
}
@media screen and (max-width: 768px) {
  .swiper-pagination.swiper-pagination-bullets .swiper-pagination-bullet {
    margin: 0 5px;
  }
}
.swiper-pagination .swiper-pagination-bullet {
  width: 11px;
  height: 11px;
  display: block;
  border-radius: 10px;
  background: #062744;
  opacity: 0.2;
  transition: all .3s;
}
.swiper-pagination .swiper-pagination-bullet-active {
  opacity: 1;
  background: #c4ac6f;
  height: 30px;
}
@media screen and (max-width: 768px) {
  .swiper-pagination .swiper-pagination-bullet-active {
    height: 11px;
    width: 30px;
  }
}