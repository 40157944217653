.post {
	padding-top: 100px;
	display: flex;
	flex-direction: column;
	margin: 0 auto;
	width: 80%;
}

.post h1 {
  align-self: center;
  font-family: 'josefin sans';
  font-size: 60px;
  font-weight: 700;
  color: #c4ac6f;
}

.post img {
  max-width:100%;
  max-height:100%;
  padding-bottom: 50px;
}

.post .content {
	color: #fff;
	width: 80%;
  align-self: center;
}